
























import { Component, Ref } from 'vue-property-decorator';
import FormDialogComponent from '@/mixins/form-dialog-component';
import { AssetEntityModel, AssetDetailEntityModel } from '@/entity-model/asset-entity';
import FormComponent from '@/mixins/form-component';
import { ViewModeType } from '@/model/enum';
import AssetService from '@/service/asset';
import { ICRUDQ } from '@/model/interface';

@Component
export default class AssetEditDialog extends FormDialogComponent<AssetEntityModel> {
    @Ref()
    basicForm: FormComponent<AssetEntityModel>;
    @Ref()
    detailForm: FormComponent<any>;

    /**
     * 打开弹窗
     * @param model 数据模型
     * @param service 增删改查服务
     * @param needGetDetail 是否需要调用详情接口
     */
    dialogOpen(model: AssetEntityModel, service: ICRUDQ<AssetEntityModel, any>, viewMode: ViewModeType, needGetDetail: boolean = false): void {
        this.dialogVisible = true;
        this.viewMode = viewMode;
        this.initDialogTitle(this.viewMode);
        if (needGetDetail && service) {
            this.startFullScreenLoading();
            service.retrieve(_.get(model, 'id')).then(res => {
                const basicModel = new AssetEntityModel().toModel(_.get(res, 'basic'));
                const detailModel = new AssetDetailEntityModel().toModel(_.get(res, 'detail'));
                this.assetDialogInit(basicModel, detailModel, viewMode);
            }).catch(err => $log.error(err)).finally(() => {
                this.stopFullScreenLoading();
            });
        } else {
            this.assetDialogInit(model, new AssetDetailEntityModel(), viewMode);
        }
    }

    drawDialogOpen(basicModel: AssetEntityModel, detailModel: AssetDetailEntityModel, viewMode: ViewModeType = ViewModeType.UPDATE) {
        this.dialogVisible = true;
        this.viewMode = viewMode;
        this.initDialogTitle(this.viewMode);
        this.assetDialogInit(basicModel, detailModel, viewMode);
    }

    assetDialogInit(basicModel: AssetEntityModel, detailModel: AssetDetailEntityModel, viewMode: ViewModeType) {
        this.$nextTick(() => {
            if (this.basicForm) {
                this.basicForm.initForm(basicModel, viewMode);
            }
            if (this.detailForm) {
                this.detailForm.initForm(detailModel, viewMode);
            }
        });
    }

    /**
     * 点击弹窗保存事件
     * 提交表单数据
     */
    dialogOK(): Promise<any> {
        return new Promise((resolve, reject) => {
            Promise.all([this.basicForm.submitForm(), this.detailForm.submitForm()]).then(res => {
                const model = Object.assign(this.basicForm.formModel, this.detailForm.formModel);
                delete model.groupType;
                delete model.uuid;
                delete model.useDepartmentName;
                delete model.professionalModelName;
                AssetService.saveAsset(model).then(res => {
                    resolve(null);
                    this.dialogClose();
                    this.$emit('dialogOK', res);
                }).catch(err => {
                    reject(err);
                });
            }).catch((err) => {
                reject(err);
            });
        });
    }

    /**
     * 关闭弹窗
     */
    dialogClose(): void {
        if (this.basicForm) {
            this.basicForm.resetFields();
            this.basicForm.clearValidate();
        }
        if (this.detailForm) {
            this.detailForm.resetFields();
            this.detailForm.clearValidate();
        }
        this.dialogVisible = false;
        this.dialogTitle = null;
    }
}
