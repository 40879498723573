






















































































































import { Component } from 'vue-property-decorator';
import DrawerComponent from '@/mixins/drawer-component';
import { AssetEntityModel, AssetDetailEntityModel } from '@/entity-model/asset-entity';
import AssetEditDialog from './asset-edit.vue';
import AssetService from '@/service/asset';
import DeviceStatusComponent from '../device/device-status.vue';

@Component({
    components: {
        'asset-edit-dialog': AssetEditDialog,
        'device-status-component': DeviceStatusComponent
    }
})
export default class AssetDetailDrawer extends DrawerComponent {
    basicModel: AssetEntityModel = null;
    detailModel: AssetDetailEntityModel = null;
    tabIndex: string = '1';
    assetId: string = null;

    drawerShow(model: AssetEntityModel) {
        this.drawerOpen(model.id, model.assetName);
        this.init(model.id);
    }

    init(assetId: string) {
        this.assetId = assetId;
        this.startFullScreenLoading();
        AssetService.retrieve(assetId)
            .then(res => {
                this.basicModel = new AssetEntityModel().toModel(_.get(res, 'basic'));
                this.detailModel = new AssetDetailEntityModel().toModel(_.get(res, 'detail'));
            })
            .finally(() => {
                this.stopFullScreenLoading();
            });
    }

    reload() {
        // this.tabIndex = '1';
        this.init(this.assetId);
    }
    editClick() {
        (this.$refs.formDialog as AssetEditDialog).drawDialogOpen(this.basicModel, this.detailModel);
    }
}
