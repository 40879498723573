
import { AssetEntityModel, AssetDetailEntityModel } from '@/entity-model/asset-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post, put, del } from './request';

const URL_PATH = `${ASSET_BASE_REQUEST_PATH}/asset/basic`;

class AssetService implements ICRUDQ<AssetEntityModel, AssetDetailEntityModel> {
    async create(model: AssetEntityModel):Promise<AssetEntityModel> {
        const url = `${URL_PATH}`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<AssetEntityModel> {
        const url = `${URL_PATH}/${modelId}`;
        const res = await get(url);
        return res;
        // return new AssetEntityModel().toModel(res);
    }

    async update(model: AssetEntityModel):Promise<AssetEntityModel> {
        const url = `${URL_PATH}`;
        const params = model.toService();
        const res = await put(url, params);
        return res;
    }

    async delete(model: AssetEntityModel):Promise<AssetEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: AssetDetailEntityModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new AssetEntityModel().toModel(item));
        return res;
        // 不带分页功能的处理方法
        // return _.map(res, item => item = new AssetEntityModel().toModel(item));
    }

    async saveAsset(model: any):Promise<any> {
        const url = `${URL_PATH}/save`;
        const res = await post(url, model);
        return res;
    }
}

export default new AssetService();
