










































































import { Component, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@/mixins/table-dialog-form-component';
import GroupComponent from '@/mixins/group-component';
import { AssetEntityModel, AssetQueryModel } from '@/entity-model/asset-entity';
import AssetService from '@/service/asset';
import AssetDetailDrawer from './asset-detail-drawer.vue';
import AssetEditDialog from './asset-edit.vue';
import AssetBatchAddDialog from './asset-batch-add-dialog.vue';

@Component({
    components: {
        'asset-detail-drawer': AssetDetailDrawer,
        'asset-edit-dialog': AssetEditDialog,
        'asset-batch-add-dialog': AssetBatchAddDialog
    }
})
export default class AssetListComponent extends Mixins(TableDialogFormComponent, GroupComponent) {
    AssetEntityModel = AssetEntityModel;
    // allAssetStatus: Array<any> = null;
    filterData: Array<any> = null;

    // get AllStatusOptions() {
    //     return this.getAllOptions(this.allAssetStatus);
    // }

    created() {
        this.initTable({
            service: AssetService,
            queryModel: new AssetQueryModel(),
            tableColumns: AssetEntityModel.getTableColumns()
        });
        // CommonService.getPropertyOptions(PropertyEntityType.ASSET_STATUS).then(res => {
        //     this.allAssetStatus = res;
        // });
    }
    treeSelect(groupIds: Array<string>) {
        const groupId = _.first(groupIds);
        (this.queryModel as AssetQueryModel).groupIds = groupIds;
        if (groupId) {
            this.$router.push(`/dashboard/asset-list?groupId=${groupId}`);
        } else {
            this.$router.push('/dashboard/asset-list');
        }
    }

    detailClick(model: AssetEntityModel) {
        if (this.$refs.detailDrawer) {
            (this.$refs.detailDrawer as AssetDetailDrawer).drawerShow(model);
        }
    }
    async downloadQRcodeClick(e) {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择资产');
            return;
        }
        this.startFullScreenLoading('正在下载二维码。。。');
        let selectRowIds = this.getSelectedRowIds();
        const selectedRows = [];
        this.filterData = [];
        _.forEach(selectRowIds, (value) => {
            const row: any = _.find(this.listData, rowId => rowId['id'] === value);
            selectedRows.push({ id: `deviceId${row.id}`, code: row.assetNo, name: row.assetName });
        });
        let downloadIndex = 0;
        this.filterData.push(selectedRows[downloadIndex]);
        let timer2 = setInterval(() => {
            if (this.$refs.qrcodeComponents[downloadIndex].success) {
                this.$refs.qrcodeComponents[downloadIndex].download();
                downloadIndex++;
                this.filterData.push(selectedRows[downloadIndex]);
                if (downloadIndex === selectedRows.length) {
                    clearInterval(timer2);
                    timer2 = null;
                    this.stopFullScreenLoading();
                }
            }
        }, 100);

        // this.$nextTick(() => {
        //     let timer = setInterval(() => {
        //         const qrcodeList = this.$refs.qrcodeComponents as Array<any>;
        //         if (_.filter(qrcodeList, (item:any) => item.success).length === qrcodeList.length) {
        //             clearInterval(timer);
        //             timer = null;
        //             const qrCodeImgArr = qrcodeList.map(item => item.getImgUrl());

        //             // const zip = new JSZip();
        //             qrCodeImgArr.forEach((item) => {
        //                 let fileName = item.qrInfo.name;
        //                 zip.file(fileName + '.png', item.url.substring(22), { base64: true });
        //             });
        //             zip.generateAsync({ type: 'blob' }).then(content => {
        //                 this.stopFullScreenLoading();
        //                 FileSaver.saveAs(content, '二维码.zip');
        //             });
        //         }
        //     }, 200);
        // });
    }

    addBatchClick(model: AssetEntityModel) {
        (this.$refs.formBatchAddDialog as AssetBatchAddDialog).dialogOpen(this.GroupId, _.get(model, 'id'));
    }
}

