var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: _vm.drawerTitle,
        placement: _vm.placement,
        width: _vm.width,
        closable: _vm.closable,
        visible: _vm.drawerVisible,
        "destroy-on-close": true
      },
      on: { close: _vm.drawerClose }
    },
    [
      _c("div", { staticClass: "asset-detail-component table-component" }, [
        _c(
          "div",
          { staticClass: "page-body page-body-bg-white" },
          [
            _c(
              "a-tabs",
              {
                attrs: { type: "card", "default-active-key": "1" },
                model: {
                  value: _vm.tabIndex,
                  callback: function($$v) {
                    _vm.tabIndex = $$v
                  },
                  expression: "tabIndex"
                }
              },
              [
                _c(
                  "a-tab-pane",
                  { key: "1", attrs: { tab: "资产信息" } },
                  [
                    _c(
                      "a-descriptions",
                      {
                        staticClass: "tab-margin-16",
                        attrs: { bordered: "", column: 2 }
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "title" }, slot: "title" },
                          [
                            _c("span", [_vm._v("基本信息")]),
                            _c(
                              "jtl-button",
                              {
                                staticClass: "description-title-button",
                                attrs: { type: "primary" },
                                on: { click: _vm.editClick }
                              },
                              [_vm._v("编辑")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "资产编号" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm._.get(_vm.basicModel, "assetNo")) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "资产名称" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm._.get(_vm.basicModel, "assetName")) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "资产分组" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm._.get(_vm.basicModel, "groupName")) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "所属专业" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._.get(
                                    _vm.basicModel,
                                    "professionalModelName"
                                  )
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "资产位置" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._.get(_vm.basicModel, "positionName")
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "资产状态" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._.get(_vm.basicModel, "statusName")
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "创建时间" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._.get(_vm.basicModel, "createdTime")
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-descriptions",
                      {
                        staticClass: "tab-margin-16",
                        attrs: { bordered: "", column: 2, title: "详细信息" }
                      },
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "规格型号" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._.get(_vm.detailModel, "specification")
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "参数" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._.get(_vm.detailModel, "parameter")
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "品牌" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm._.get(_vm.detailModel, "brand")) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "厂家名称" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._.get(_vm.detailModel, "manufacturerName")
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "厂家电话" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._.get(_vm.detailModel, "manufacturerTel")
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "生产厂家" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._.get(_vm.detailModel, "manufacturer")
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "供应商" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm._.get(_vm.detailModel, "supplier")) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "供应商电话" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._.get(_vm.detailModel, "supplierTel")
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "生产日期" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    _vm._.get(_vm.detailModel, "productionTime")
                                  )
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "出厂设备编号" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm._.get(_vm.detailModel, "deviceNo")) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "到货日期" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    _vm._.get(_vm.detailModel, "arrivalTime")
                                  )
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "安装单位" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._.get(_vm.detailModel, "installUnit")
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "验收单位" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._.get(_vm.detailModel, "acceptUnit")
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "验收时间" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    _vm._.get(_vm.detailModel, "acceptTime")
                                  )
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "维护周期" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._.get(_vm.detailModel, "maintenanceCycle")
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "原厂到保日期" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    _vm._.get(_vm.detailModel, "keepTime")
                                  )
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "使用部门" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._.get(
                                    _vm.detailModel,
                                    "useDepartmentName"
                                  )
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "管理部门" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._.get(
                                    _vm.detailModel,
                                    "manageDepartmentName"
                                  )
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "外形尺寸" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm._.get(_vm.detailModel, "size")) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "重量" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm._.get(_vm.detailModel, "weight")) +
                                " Kg "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-tab-pane",
                  { key: "2", attrs: { tab: "运行状态" } },
                  [
                    _c("device-status-component", {
                      attrs: { "device-id": _vm._.get(_vm.basicModel, "id") }
                    })
                  ],
                  1
                ),
                _c("a-tab-pane", { key: "3", attrs: { tab: "知识库" } }),
                _c("a-tab-pane", { key: "4", attrs: { tab: "运行日志" } })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("asset-edit-dialog", {
        ref: "formDialog",
        on: { dialogOK: _vm.reload }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }