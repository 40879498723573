import { BaseModel, BaseEntityModel } from '@/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlModel, FormControlOptionModel, FormControlSelectGroupModel, FormControlDateModel } from '@/model/form-control';
import { ThingsTemplateQueryModel } from './things-template-entity';
import CommonService from '@/service/common';
import OrgService from '@/service/org';
import { GroupEntityType, PropertyEntityType } from '@/model/enum';
import SpaceV2Service from '@/service/space-v2';
import { QueryControl, QueryControlType } from '@/model/query-control';

export class AssetEntityModel extends BaseEntityModel {
    constructor(groupId?: string) {
        super();
        this.groupId = groupId;
        this.groupType = GroupEntityType.ASSET;
    }

    /**
     * 资产编号
     */
    assetNo: string = undefined;

    @FormControl({
        label: '资产分组',
        type: FormControlType.SELECT_GROUP,
        required: false,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlSelectGroupModel)
    groupId: string = undefined;
    groupName: string = undefined;
    groupType: GroupEntityType;

    @FormControl({
        label: '所属专业',
        type: FormControlType.TREE_SELECT,
        optionsPromise: CommonService.getProfessionalTree,
        required: false,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlOptionModel)
    professionalModel: string = undefined;
    professionalModelName: string = undefined;

    // @FormControl({
    //     label: '关联设备',
    //     type: FormControlType.SELECT_DEVICE,
    //     required: false,
    //     layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    // } as FormControlModel<string>)
    // deviceId: string = undefined;

    @FormControl({
        label: '资产名称',
        type: FormControlType.TEXT,
        required: true,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    assetName: string = undefined;

    @FormControl({
        label: '安装位置',
        type: FormControlType.TREE_SELECT,
        optionsPromise: SpaceV2Service.getLocationTree,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlModel<string>)
    positionId: string = undefined;
    positionName: string = undefined;

    @FormControl({
        label: '资产状态',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getPropertyOptions,
        optionsPromiseParam: PropertyEntityType.ASSET_STATUS,
        required: true,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlOptionModel)
    status: string = undefined;
    statusName: string = undefined;

    @FormControl({
        label: '负责人',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getPersonList,
        mode: 'default',
        required: false,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlOptionModel)
    headId: string = undefined;

    useDepartment: string = undefined;
    useDepartmentName: string = undefined;
    manageDepartment: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '资产名称',
                dataIndex: 'assetName',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '安装位置',
                dataIndex: 'positionName'
            },
            {
                title: '使用部门',
                dataIndex: 'useDepartmentName'
            },
            {
                title: '状态',
                dataIndex: 'statusName'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        const data: any = super.toService();
        return data;
    }
}

export class AssetDetailEntityModel extends BaseModel {
    @FormControl({
        label: '规格型号',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    specification: string = undefined;

    @FormControl({
        label: '参数',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    parameter: string = undefined;

    @FormControl({
        label: '品牌',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    brand: string = undefined;

    @FormControl({
        label: '厂家名称',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    manufacturerName: string = undefined;

    @FormControl({
        label: '厂家电话',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    manufacturerTel: string = undefined;

    @FormControl({
        label: '生产厂家',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    manufacturer: string = undefined;

    @FormControl({
        label: '供应商',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    supplier: string = undefined;

    @FormControl({
        label: '供应商电话',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    supplierTel: string = undefined;

    @FormControl({
        label: '生产日期',
        type: FormControlType.SELECT_DATE,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlDateModel)
    productionTime: string = undefined;

    @FormControl({
        label: '出厂设备编号',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlDateModel)
    deviceNo: string = undefined;

    @FormControl({
        label: '到货日期',
        type: FormControlType.SELECT_DATE,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlDateModel)
    arrivalTime: string = undefined;

    @FormControl({
        label: '安装单位',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    installUnit: string = undefined;

    @FormControl({
        label: '验收单位',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    acceptUnit: string = undefined;

    @FormControl({
        label: '验收时间',
        type: FormControlType.SELECT_DATE,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlDateModel)
    acceptTime: string = undefined;

    @FormControl({
        label: '维护周期',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    maintenanceCycle: string = undefined;

    @FormControl({
        label: '原厂到保日期',
        type: FormControlType.SELECT_DATE,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlDateModel)
    keepTime: string = undefined;

    @FormControl({
        label: '使用部门',
        type: FormControlType.TREE_SELECT,
        optionsPromise: OrgService.getSelectOrgTree,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlOptionModel)
    useDepartment: string = undefined;
    useDepartmentName: string = undefined;

    @FormControl({
        label: '管理部门',
        type: FormControlType.TREE_SELECT,
        optionsPromise: OrgService.getSelectOrgTree,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' },
        required: false
    } as FormControlOptionModel)
    manageDepartment: string = undefined;
    manageDepartmentName: string = undefined;

    @FormControl({
        label: '外形尺寸',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    size: string = undefined;

    @FormControl({
        label: '重量',
        type: FormControlType.NUMBER,
        step: 0.1,
        message: 'Kg',
        noMessageTooltip: true,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    weight: string = undefined;
}

export class AssetQueryModel extends ThingsTemplateQueryModel {
    @QueryControl({
        label: '状态',
        type: QueryControlType.SELECT,
        optionsPromise: CommonService.getPropertyOptions,
        optionsPromiseParam: PropertyEntityType.ASSET_STATUS,
        hasAllOption: true
    })
    status: string = '';

    @QueryControl({
        label: '安装位置',
        type: QueryControlType.TREE_SELECT,
        selectChildren: true,
        optionsPromise: SpaceV2Service.getLocationTree
    })
    locationIds: string = undefined;

    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT,
        span: 10
    })
    assetName: string = undefined;

    toService() {
        // const data: any = super.toService();
        // return data;
        return {
            params: {
                groupIds: this.GroupIds,
                name: this.assetName,
                status: this.status,
                // positionId: this.spaceId,
                locationIds: this.locationIds
            }
        };
    }
}
