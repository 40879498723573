





























































import { Component, Ref } from 'vue-property-decorator';
import TableComponent from '@/mixins/table-component';
import { GroupEntityType, PropertyEntityType } from '@/model/enum';
import { DeviceEntityModel, DeviceQueryModel } from '@/entity-model/device-entity';
import { FormControlType } from '@/model/form-control';
import CommonService from '@/service/common';
import DeviceService from '@/service/device';
import FormComponent from '@/mixins/form-component';
import ThingsGroupService from '@/service/things-group';

@Component
export default class FacilityBatchAddDialog extends TableComponent<DeviceEntityModel, DeviceQueryModel> {
    dialogVisible: boolean = false;
    GroupEntityType = GroupEntityType;
    parentId: string;
    rightDataList: Array<any> = [];

    @Ref('facilityForm')
    jtlForm: FormComponent<any>;

    created() {
        const deviceQueryModel = new DeviceQueryModel();
        deviceQueryModel.forceRoot = false;
        this.isLoadMore = true;
        this.initTable({
            listFunc: DeviceService.listAssetDevs,
            queryModel: deviceQueryModel
        });
    }

    dialogOpen(groupId: string, parentId: string) {
        this.parentId = parentId;
        this.dialogVisible = true;
        this.rightDataList = [];
        this.getList();

        this.$nextTick(() => {
            if (this.jtlForm) {
                const controlItems = [
                    {
                        key: 'groupId',
                        label: '资产分组',
                        value: groupId ? [groupId] : null,
                        type: FormControlType.TREE_SELECT,
                        optionsPromise: ThingsGroupService.getGroupTree,
                        optionsPromiseParam: GroupEntityType.ASSET,
                        layout: { labelCol: 2, wrapperCol: 18, width: '100%' },
                        required: true
                    },
                    {
                        key: 'professionalModelId',
                        label: '所属专业',
                        type: FormControlType.TREE_SELECT,
                        optionsPromise: CommonService.getProfessionalTree,
                        layout: { labelCol: 2, wrapperCol: 18, width: '100%' },
                        required: true
                    },
                    {
                        key: 'status',
                        label: '资产状态',
                        type: FormControlType.SELECT,
                        optionsPromise: CommonService.getPropertyOptions,
                        optionsPromiseParam: PropertyEntityType.ASSET_STATUS,
                        required: true,
                        layout: { labelCol: 2, wrapperCol: 18, width: '100%' }
                    }
                ];
                (this.jtlForm as any).initFormByControls(controlItems);
            }
        });
    }

    dialogOK() {
        if (!this.jtlForm) {
            return;
        }
        return new Promise((resolve, reject) => {
            this.jtlForm.submitForm().then((ret) => {
                this.showDeveloping();
                // FacilityV2Service.batchCreateFacility({ facilities: params, groupIds: this.jtlForm.formModel['groupIds'] }).then((ret) => {
                //     this.dialogClose();
                //     this.$emit('dialogOK');
                //     resolve(true);
                // }).catch(err => {
                //     reject();
                //     throw err;
                // });
            }).catch(err => {
                reject();
                throw err;
            });
        });
    }

    dialogClose(): void {
        this.listData = null;
        this.rightDataList = [];
        this.parentId = null;
        this.queryModel.groupIds = undefined;
        this.queryModel.name = undefined;
        this.dialogVisible = false;
    }

    checkOnChange(model: any) {
        if (model.check) {
            this.rightDataList.push({ id: model.id, name: model.displayName, deviceTypeId: this.queryModel.typeId });
        } else {
            const index = _.findIndex(this.rightDataList, item => item.id === model.id);
            if (index > -1) {
                this.rightDataList.splice(index, 1);
            }
        }
    }

    removeItemClick(model, index) {
        model.check = false;
        const listDataItem = _.find(this.listData, item => item.id === model.id);
        if (listDataItem) {
            listDataItem.check = false;
        }
        this.rightDataList.splice(index, 1);
    }
    removeAllClick() {
        this.rightDataList = [];
        _.forEach(this.listData, item => {
            item.check = false;
        });
    }

    get AllCheck() {
        if (!this.listData || this.listData.length === 0) {
            return false;
        }
        return _.filter(this.listData, item => item.check).length === this.listData.length;
    }
    set AllCheck(val: boolean) {
        _.forEach(this.listData, item => {
            item.check = val;
            if (val) {
                const index = _.findIndex(this.rightDataList, rightItem => rightItem.id === item.id);
                if (index === -1) {
                    this.rightDataList.push({ id: item.id, name: item.displayName, deviceTypeId: this.queryModel.typeId });
                }
            }
        });
        if (!val) {
            this.rightDataList = [];
        }
    }
}
